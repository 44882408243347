import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Layout } from '../templates'
import { theme } from '../configs/accdotcom.theme'
import { mq } from '../hooks'
import {
  Body,
  TitleLarge,
  UnorderedList,
  UnorderedListItem,
  TitleMedium,
} from '../components/RichText'

export default () => {
  const { register, handleSubmit, errors } = useForm()
  const [submitted, setSubmit] = useState(false)
  const onSubmit = async data => {
    data.form = 'unsubscribe'
    const response = await fetch(
      'https://ijcjywn3cg.execute-api.us-east-1.amazonaws.com/accdotcom/accdotcom-forms',
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
      }
    )
    const r = await response.json()
    setSubmit(true)
  }
  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div css={{ margin: '2rem 0' }}>
        <label htmlFor="email">Email Address</label>
        <input
          name="email"
          id="email"
          type="email"
          placeholder="Email"
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'invalid email address',
            },
          })}
          css={mq({
            marginLeft: '2rem',
            border: '1px solid #eeeeee',
            padding: '5px 10px',
          })}
        />
        {errors.email && errors.email.message}
      </div>
      <div
        css={mq({
          ...theme.type.styles.button,
          ...theme.backgrounds.button.primary,
          borderRadius: '2.5rem',
          borderWidth: '2px',
          borderStyle: 'solid',
          padding: '.7rem 1.5rem',
          maxWidth: ['100%', '100%', '30rem'],
          width: 'auto',
          textAlign: 'center',
          width: '12rem',
        })}
      >
        <button type="submit">Unsubscribe</button>
      </div>
    </form>
  )

  const success = (
    <div css={{ margin: '2rem 0' }}>
      <Body>Success! You have been unsubscribed.</Body>
    </div>
  )

  return (
    <Layout>
      <div css={mq({ margin: theme.gutters.normal })}>
        <TitleLarge>Subscription Center</TitleLarge>
        {submitted ? success : form}
      </div>
    </Layout>
  )
}
